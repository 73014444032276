<template>
  <div>
    <div class="row">
      <div class="col-lg-6 pl-lg-5 text-center text-lg-left">
        <div class="btn list-label">2</div>
        <h2 class="pt-lg-4">Dodání šablony poukazu ve formátu PDF</h2>
      </div>
      <div class="col-lg-6 pt-2 pt-lg-0">
        <img class="img-fluid" src="@/assets/elements/ToPDF.svg" width="86%" alt="APIKey">
      </div>
    </div>

    <hr>

    <div class="col-12 col-lg-10 offset-lg-1 pb-lg-4">
      <p>V dalším kroku budeme potřebovat Vaši <strong>čistou šablonu poukazu</strong> (tedy nepředvyplněnou) ve formátu
        PDF společně <strong>s
          Vaší představou o vyplněném poukazu</strong> (je možné vyplnit kód poukazu a jeho splatnost). V případě že
        nemáte vlastní
        šablonu poukazu, můžete využít naši výchozí.</p>

      <img class="img-fluid pt-lg-4 pb-lg-4" src="@/assets/elements/Voucher-template.png" alt="Voucher template">

      <p>Jsou podporovány také jazykové mutace poukazů. V tomto případě prosíme o dodání šablony ve formátu PDF pro
        každou z jazykových mutací.</p>

      <p>Spolu se šablonou prosíme také o dodání fontu, který si přejete použít pro vepisování vygenerovaných
        informací.</p>

    </div>
    <div class="text-right pr-5 pr-lg-4">
      <img class="img-fluid pt-lg-5 img-line-right" src="@/assets/elements/line-right.png" alt="Line">
    </div>

  </div>

</template>

<script>
export default {
  name: "GetTemplate"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
}

p {
  font-family: DMSans-Regular;
  font-size: 1.3rem;
}

strong {
  color: rgb(0, 113, 206);
}

.list-label {
  font-family: DMSans-Bold;
  background: rgb(0, 113, 206);
  background: linear-gradient(rgba(0, 113, 206, 1) 0%, rgba(147, 200, 244, 1) 100%);
  border: none;
  color: white;
  border-radius: 0;
  font-size: 2rem;
  padding: .5rem 1.5rem;
}

.img-line-right {
  width: 9%;
}

@media screen and (max-width: 991px){
  .img-line-right {
    width: 13%;
  }
}

hr {
  margin: 5rem .9rem 5rem 1.2rem;
}
</style>