<template>
  <div class="pb-lg-5">
    <div class="row">
      <div class="col-lg-6 text-center text-lg-left">
        <img class="img-fluid img-price" src="@/assets/elements/price.svg" alt="APIKey">
      </div>
      <div class="col-lg-6">
        <h2 class="pt-lg-4 pb-lg-4">Cena za službu</h2>
        <div class="row blue-back">
          <div class="col-lg-6">
            <h4>Jednorázový poplatek</h4>
          </div>
          <div class="col-lg-6 text-right">
            <h3>2500,- Kč <small>bez DHP</small></h3>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-7 white-blue-back">
            <div class="row">
              <div class="col-lg-6">
                <h6>Měsíční paušál</h6>
              </div>
              <div class="col-lg-6">
                <h5>350,- Kč <small>bez DPH</small> </h5>
              </div>
            </div>

          </div>
          <div class="col-lg-6"></div>

        </div>

        <div class="text-block">
          <p>Za nasazení této služby a vyřešení veškerých technických záležitostí účtujeme jednorázový poplatek <strong>2500
            Kč</strong> bez DPH.</p>
          <p>Dále účtujeme pouze měsíční paušál ve výši <strong>350 Kč</strong> bez DPH, díky kterému Vám zaručíme chod
            poukazového systému na
            našem stabilním serveru a zákaznickou podporu v případě jakýchkoliv problémů či dotazů.</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "APIKey"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
}

h3 {
  font-size: 2.2rem;
  font-family: DMSans-Bold;
  color: white;
  margin-bottom: 0;
}

h4 {
  padding-top: .4rem;
  font-family: DMSans-Regular;
  color: white;
  margin-bottom: 0;
}

h5 {
  color: rgb(0, 113, 206);
  padding-top: .4rem;
  font-family: DMSans-Bold;
  margin-bottom: 0;
}

h6 {
  color: #4e555b;
  padding-top: .5rem;
  font-family: DMSans-Regular;
  margin-bottom: 0;
}

p {
  font-family: DMSans-Regular;
  font-size: 1.1rem;
}

strong {
  color: rgb(0, 113, 206);
}

small {
  font-size: 50%;
}

.blue-back {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background-color: rgb(0, 113, 206);
}

.white-blue-back {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  background-color: #F3F9FF;
}
.text-block {
  padding-top: 4em;
}

.img-price {
  width: 90%;
}

@media screen and (min-width: 530px) and (max-width: 991px) {
  .img-price {
    width: 60%;
  }
}
hr {
  margin: 5rem .9rem 5rem 1.2rem;
}
</style>