<template>
  <div>
    <div class="container pt-lg-2">
      <header-app></header-app>
      <div class="label">
        <h2 class="pt-lg-5 text-lg-left">Manuál pro nasazení systému</h2>
        <h1 class="text-lg-left">Voucher Creator</h1>
      </div>


      <div class="text-center arrow">
        <img class="text-center img-fluid" src="@/assets/elements/arrow.png" alt="Arrow">
      </div>

      <div class="pt-lg-5">
        <div class="row entrance">
          <div class="col-lg-6 pl-lg-5 pb-2 pb-lg-0 text-lg-left">
            <h3>Postup pro nasazení automatické správy dárkových poukazů pro e-shopy Upgates</h3>
          </div>
          <div class="col-lg-6 pr-0 pr-lg-5 text-lg-left">
            <p>Vážená zákaznice, vážený zákazníku,<br><br>
              děkujeme za zájem o naši službu. V tomto manuálu naleznete popis všech potřebných kroků ke spuštění
              systému
              pro správu poukazů na Vašem e-shopu.<br><br>
              Pokud si s některým bodem manuálu nebudete vědět rady, neváhejte nás kontaktovat. Rádi Vám pomůžeme.
            </p>
          </div>
        </div>
      </div>

      <apikey-app class="space pb-lg-4"></apikey-app>

      <gettemplace-app class="space"></gettemplace-app>

      <smpt-app class="space"></smpt-app>

      <upgates-app class="space"></upgates-app>
    </div>
    <hr>
    <div class="container">
      <price-app class="space"></price-app>
    </div>
    <footer-app class="space"></footer-app>

  </div>
</template>

<script>
import Header from "./parts/Header";
import APIKey from "./parts/APIKey";
import GetTemplate from "./parts/GetTemplate";
import SMTP from "./parts/SMTP";
import UpgatesStatus from "./parts/UpgatesStatus";
import Price from "./parts/Price";
import Footer from "./parts/Footer";

export default {
  name: 'HelloWorld',
  components: {
    'header-app': Header,
    'apikey-app': APIKey,
    'gettemplace-app': GetTemplate,
    'smpt-app': SMTP,
    'upgates-app': UpgatesStatus,
    'price-app': Price,
    'footer-app': Footer
  }

}
</script>

<style scoped>
h1 {
  font-family: DMSans-Bold;
  font-size: 3.6rem;
}

.label, h2 {
  font-family: DMSans-Regular;
  font-size: 3.6rem;
}

h3 {
  font-family: DMSans-Medium;
  color: rgb(0, 113, 206);
  line-height: 1.3;
}

p {
  font-family: DMSans-Regular;
}

hr {
  margin-top: 1.5em;
}

.space {
  padding-top: 8rem;
}

@media screen and (max-width: 991px){
  .space {
    padding-top: 3rem;
  }

}

.entrance {
  padding-top: 4em;
  padding-bottom: 3em;
  background-color: #F3F9FF;
}

.arrow {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 2%;
}

@media screen and (max-width: 991px) {
  .arrow {
    width: 6%;
    text-align: center;
  }
}

</style>
