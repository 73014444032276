<template>
  <div id="footer">
    <div class="container pb-lg-5">
      <div class="row pt-lg-3">
        <div class="col-lg-6 col-12">
          <h3>Nevíte si s něčím rady? <br>Neváhejte se nám ozvat.</h3>
        </div>
        <div class="col-lg-6 col-12 text-center text-lg-right">
          <h2><a href="mailto:info@dokamar.cz">info@dokamar.cz</a></h2>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-6 col-12 text-center text-lg-left">
          <a href="https://dokamar.cz/#/">Dokamar s.r.o.</a>
        </div>
        <div class="col-lg-6 col-12 text-center text-lg-right">
          <p>2021-2022 Voucher Creator</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
  color: white;
}
@media screen and (max-width: 991px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-family: DMSans-Regular;
  font-size: 1.9rem;
  color: white;
}
p {
  font-family: DMSans-Regular;
  color: white;
}
a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
  color: white;
}

hr {
  margin-bottom: 2em;
  margin-top: 5em;
  border-top: solid 1px #474747;
}
#footer {
  background-color: #0a1a27;
}
</style>