<template>
  <div class="pb-lg-5">
    <div class="row">
      <div class="col-lg-6 pl-lg-5 text-center text-lg-left">
        <div class="btn list-label">3</div>
        <h2 class="pt-lg-4">Dodaní údajů k SMTP serveru</h2>
      </div>
      <div class="col-lg-6 pt-2 pt-lg-0">
        <img class="img-fluid" src="@/assets/elements/SMTP.svg" width="90%" alt="APIKey">
      </div>
    </div>

    <hr>

    <div class="col-12 col-lg-10 offset-lg-1 pb-lg-4">
      <p>Přístup k SMTP serveru je nezbytný k nastavení e-mailové adresy, ze které zákazník obdrží Váš dárkový poukaz.
        Získáte ho u <strong>poskytovatele Vašeho e-mailového serveru.</strong>
      </p>
      <p>Budeme potřebovat znát <strong>adresu SMTP serveru</strong> (například smtp.forpsi.com), <strong>port</strong>
        na kterém běží, <strong>přihlašovací
          jméno, heslo</strong> a <strong>e-mailovou adresu</strong>, ze které bude e-mail odeslán.</p>

    </div>
    <div class="pl-5 pl-lg-0">
      <img class="img-fluid pt-lg-5 img-line-left" src="@/assets/elements/line-left.png" alt="Line">
    </div>

  </div>
</template>

<script>
export default {
  name: "APIKey"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
}

p {
  font-family: DMSans-Regular;
  font-size: 1.3rem;
}

strong {
  color: rgb(0, 113, 206);
}

.list-label {
  font-family: DMSans-Bold;
  background: rgb(0, 113, 206);
  background: linear-gradient(rgba(0, 113, 206, 1) 0%, rgba(147, 200, 244, 1) 100%);
  border: none;
  color: white;
  border-radius: 0;
  font-size: 2rem;
  padding: .5rem 1.5rem;
}
.img-line-left {
  width: 8%;
}

@media screen and (max-width: 991px){
  .img-line-left {
    width: 13%;
  }
}

hr {
  margin: 5rem .9rem 5rem 1.2rem;
}
</style>