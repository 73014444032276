<template>
  <div class="pb-lg-5">
    <div class="row">
      <div class="col-lg-6 pl-lg-5 text-center text-lg-left">
        <div class="btn list-label">1</div>
        <h2 class="pt-lg-4">Dodání API klíče</h2>
      </div>
      <div class="col-lg-6 pt-2 pt-lg-0">
        <img class="img-fluid" src="@/assets/elements/APIKey.svg" width="80%" alt="APIKey">
      </div>
    </div>

    <hr>

    <div class="col-12 col-lg-10 offset-lg-1 pb-lg-4">
      <p>Klíč naleznete v <strong>administračním centru Upgates</strong> v bočním panelu v sekci
        “<strong>Doplňky</strong>” a podsekci “<strong>API</strong>”.<br> Zde stačí vytvořit nový klíč a ten nám zaslat.
      </p>


      <img class="img-fluid pt-0 pb-0 pt-lg-4 pb-lg-4" src="@/assets/elements/upgatesAPI.png" alt="Upgates Key">

      <p>Tento klíč budeme potřebovat ke dvěma věcem. Jednak ke zjištění, které objednávky v sobě zahrnují dárkové
        poukazy, druhak ke změně stavů objednávek (například ze stavu “platba přijata” na “poukaz doručen”). Je tedy
        potřeba udělit oprávnění pro čtení a zápis pro Dárkové poukazy a Objednávky.</p>
      <p>Data o žádných objednávkách nejsou na serverech ukládány či jinak zpracovávány. Slouží pouze k rozpoznání, že
        došlo k vytvoření objednávky s dárkovým poukazem, vytvoření poukazu a ke změně stavu objednávky po jeho úspěšném
        odeslání.</p>

    </div>
    <div class="pl-5 pt-lg-0">
      <img class="img-fluid pt-lg-5 img-line-left" src="@/assets/elements/line-left.png" alt="Line">
    </div>

  </div>
</template>

<script>
export default {
  name: "APIKey"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
}

p {
  font-family: DMSans-Regular;
  font-size: 1.3rem;
}

strong {
  color: rgb(0, 113, 206);
}

.list-label {
  font-family: DMSans-Bold;
  background: rgb(0, 113, 206);
  background: linear-gradient(rgba(0, 113, 206, 1) 0%, rgba(147, 200, 244, 1) 100%);
  border: none;
  color: white;
  border-radius: 0;
  font-size: 2rem;
  padding: .5rem 1.5rem;
}
.img-line-left {
  width: 8%;
}

@media screen and (max-width: 991px){
  .img-line-left {
    width: 13%;
  }
}

hr {
  margin: 5rem .9rem 5rem 1.2rem;
}
</style>