<template>
  <div class="pb-lg-5">
    <div class="row">
      <div class="col-lg-6 pl-lg-5 text-center text-lg-left">
        <div class="btn list-label">4</div>
        <h2 class="pt-lg-4">Vytvoření stavů objednávek na Upgates</h2>
      </div>
      <div class="col-lg-6 pt-2 pt-lg-0">
        <img class="img-fluid" src="@/assets/elements/upgatesStatus.svg" width="90%" alt="APIKey">
      </div>
    </div>

    <hr>

    <div class="col-12 col-lg-10 offset-lg-1 pb-lg-4">
      <p>Finálním krokem je sladění stavů objednávek. Je třeba <strong>vytvořit dva nové stavy objednávek</strong>. První indikující
        úspěšné odeslání poukazu, druhý situaci, kdy byly poukazy v rámci objednávky dodány, ale objednávka obsahuje
        také jiné zboží, které je ještě nutno expedovat.</p>
      <p>Vytvoříte tedy například následující dva stavy pro objednávky: “Poukaz odeslán” a “Poukaz odeslán, nutno
        odeslat zboží”.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "APIKey"
}
</script>

<style scoped>
h2 {
  font-family: DMSans-Bold;
  font-size: 3rem;
}

p {
  font-family: DMSans-Regular;
  font-size: 1.3rem;
}

strong {
  color: rgb(0, 113, 206);
}

.list-label {
  font-family: DMSans-Bold;
  background: rgb(0, 113, 206);
  background: linear-gradient(rgba(0, 113, 206, 1) 0%, rgba(147, 200, 244, 1) 100%);
  border: none;
  color: white;
  border-radius: 0;
  font-size: 2rem;
  padding: .5rem 1.5rem;
}

hr {
  margin: 5rem .9rem 5rem 1.2rem;
}
</style>