<template>
  <div>
    <nav class="navbar pt-lg-4">
      <div class="row">
        <div class="col-lg-6 col-md-12 pt-2 pl-lg-0 text-center text-lg-left">
          <a class="navbar-brand" href="/">
            <img class="img-fluid logo" src="@/assets/logo/Voucher_Creator_Logo.jpg" alt="Logo">
          </a>
        </div>
        <div class="col-lg-6 col-mg-12 pt-2 pl-lg-0 text-center text-lg-right">
          <a href="mailto:info@dokamar.cz">
            <button type="button" class="btn btn-contact">Kontaktujte nás</button>
          </a>
        </div>
      </div>
    </nav>
    <hr>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.btn-contact {
  background: rgb(0, 113, 206);
  background: linear-gradient(rgba(0, 113, 206, 1) 0%, rgba(147, 200, 244, 1) 100%);
  border: none;
  color: white;
  border-radius: 0;
  font-size: 1rem;
  padding: 1rem 1.5rem;
}

hr {
  margin-top: 1.5em;
  margin-right: .9rem;
  margin-left: 1.2rem;
}
.logo {
  width: 60%;
}

@media screen and (max-width: 991px){
  .logo {
    width: 80%;
  }

}
</style>